import React from 'react'
import "./certificate.css"

function Certificate() {


  return (
        <div className='certi-content'>
              <div className='certificate-picture'>
                  <img src='assets/pics/certificate.jpg'alt=''/>
              </div>

              <div className='c-textBox'>
                  <h1>Join Mathshub, Make a Difference</h1> 
                  <p>
                    Mathshub Melbourne proudly holds the Volunteering Victoria Inclusive Volunteering certificate, awarded in recognition of our commitment to
                    fostering a diverse and welcoming environment for all volunteers. Presented in 2023, this prestigious certificate acknowledges our efforts
                    to remove barriers and create meaningful volunteering opportunities for individuals from all backgrounds, abilities, and experiences.
                    We believe that a diverse volunteer team enhances our capacity to serve our community effectively and enriches the experience for everyone involved.
                  </p>  
              </div>  
        </div>

  )
}

export default Certificate