import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./Testimonials.css";

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper";

function Testimonials() {
  return (
    
    <div className='testi-background'>
      <div className="testi-title-section">
        <h1 className="testi-tittle">What Our Guardians say</h1>
      </div>
          <div class="elfsight-app-cc49a0bb-c7fb-4324-88a6-3550ecbae0d9" id="google-reviews" data-elfsight-app-lazy></div>
        {/* <Swiper
        
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 750,
          modifier:1,
          slideShadows: false,
        }}
        pagination={false}
        modules={[Autoplay, EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide className="addres-swiper-slide">
          <div className="yellow-slide">
              <img className="testi-img" src="assets/emma.png" alt=""/>
              <h1 className="testi-name">Emma</h1>
              <h5 className="testi-position">Student Guardian</h5>
              <p className="testi-about">Having chosen home tutoring for my child,
                I was initially impressed by the tutor's professionalism and dedication
                to their role. From the very beginning, they demonstrated a genuine
                passion for teaching and a sincere interest in my child's academic progress.</p>
              <p className="testi-end">Written 2023</p>
          </div>
        </SwiperSlide>
        <SwiperSlide  className="addres-swiper-slide">
          <div className="orange-slide">
              <img className="testi-img" src="assets/image 30.png" alt=""/>
              <h1 className="testi-name">Hennry</h1>
              <h5 className="testi-position">Student Guardian</h5>
              <p className="testi-about">From the start, the tutor's commitment to providing
                personalized instruction has been evident. They have taken the time to understand
                my child's individual needs and learning style, adapting their teaching methods
                accordingly. This tailored approach has helped my child grasp challenging concepts
                and build a strong foundation in their studies.</p>
              <p className="testi-end">Written 2023</p>
          </div>
        </SwiperSlide>
        <SwiperSlide  className="addres-swiper-slide">
          <div className="yellow-slide">
              <img className="testi-img" src="assets/image 29.png" alt=""/>
              <h1 className="testi-name">Emma</h1>
              <h5 className="testi-position">Student Guardian</h5>
              <p className="testi-about"> I want to express my gratitude for the tutor's
                exceptional work with my child. Their personalized instruction and supportive approach
                have made a significant impact on my child's learning. I highly recommend this tutor for
                their dedication, expertise, and positive influence on my child's educational journey.</p>
              <p className="testi-end">Written 2023</p>
          </div>
        </SwiperSlide>
        <SwiperSlide  className="addres-swiper-slide">
          <div className="orange-slide">
              <img className="testi-img" src="assets/image 31.png" alt=""/>
              <h1 className="testi-name">Danniel</h1>
              <h5 className="testi-position">Student Guardian</h5>
              <p className="testi-about">As a Father, I am truly grateful for the tutor's remarkable
                impact on my child's education. Their personalized approach, dedication, and expertise have
                greatly benefited my child's learning journey. I wholeheartedly recommend this tutor for their
                outstanding commitment and positive influence on my child's academic progress.</p>
              <p className="testi-end">Written 2023</p>
          </div>
        </SwiperSlide>
        <SwiperSlide  className="addres-swiper-slide">
          < div className="yellow-slide">
              <img className="testi-img" src="assets/image 28.png" alt=""/>
              <h1 className="testi-name">Luna</h1>
              <h5 className="testi-position">Student Guardian</h5>
              <p className="testi-about"> I am grateful for the tutor's remarkable dedication and impact on my
                child's education. Their personalized instruction and nurturing approach have been invaluable.
                I highly recommend this tutor for their exceptional commitment and positive influence on my
                child's academic journey.</p>
              <p className="testi-end">Written 2023</p>
          </div>
        </SwiperSlide>
       </Swiper > */}

    </div>
  )
}

export default Testimonials


