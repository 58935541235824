import React from 'react'
import Hero from './Hero/Hero'
import MYT from './MYT Section/MYT'
import Certificate from './certificatex/certificate'
import ServiceSectionTest from './services&review/test'
import Class from './Class schedule/Class'
import Navbar from '../../Components/navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Testimonials from './Testimonials/Testimonials'


function HomePage() {
  return (
    <div>
        <Navbar/>
        <Hero />
        <MYT />
        <Certificate />
        <ServiceSectionTest />
        <Class />
        <Testimonials />
        <Footer />
    </div>
  )
}

export default HomePage