import React from 'react'
import AboutUs from './AboutUs/AboutUs'
import Navbar from '../../Components/navbar/Navbar'
import Footer from '../../Components/Footer/Footer'


function AboutPage() {
  return (
    <div>
        <Navbar/>
        <AboutUs />
        <Footer />
    </div>
  )
}

export default AboutPage