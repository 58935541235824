import React from 'react'
import "./MYT.css"
import { useNavigate } from 'react-router-dom';


function MYT() {
  
  
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate('/about');
  };

  return (
      <div>
        <div className='home-bubbles'>
          <div className='home-bubble-1'/>
          <div className='home-bubble-2'/>
          <div className='home-bubble-3'/>
          <div className='home-bubble-4'/>
          <div className='home-bubble-5'/>
          <div className='home-bubble-6'/>
          <div className='home-bubble-7'/>
          <div className='home-bubble-8'/>
          <div className='home-bubble-9'/>
        </div>
        <div className='myt-content'>
              <div className='textBox'>
                  <h5>ABOUT US</h5>
                  <h1>Meet Your Tutor</h1>
                  <p>
                    Sahan believes that every child is unique, and tailored teaching approaches for each student can bring out the best in them. So Sahan adjusts his teaching 
                    methodologies to match each student’s needs and learning style. He commits to building a strong foundation in mathematical concepts so the student can succeed in maths. 
                    He used modern tools to facilitate teaching and courage learning through fun activities such as games and puzzels for kids.
                  </p>
                  <button onClick={handleLearnMoreClick} className='learnmore-button'>Learn More</button>
              </div>
              <div className='picture'>
                  <img src='assets/myt-png.png'alt=''/>
              </div>
        </div>
      </div>
  )
}

export default MYT