import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Contactus.css'

function Contactus() {


    const form = useRef();
  const [isFormValid, setIsFormValid] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();
    
    if (form.current.checkValidity()) {
      emailjs.sendForm(
        'service_6zwufzl',
        'template_ovwnncc',
        form.current,
        'l56PONJXnheGbshIZ')


        .then((result) => {
            console.log(result.text);
            alert('Your mail is sent!');
            setIsFormValid(true);
        },
        (error) => {
            console.log(error.text);
        }
    );
   } else {
        setIsFormValid(false);
    }
};   

  return (
        <div class="background">
            <div className='c-u-title-section'>
                <div className="golls">
                    <div class="goll1"/>
                    <div class="goll2"/>
                    <div class="goll3"/>
                    <div class="goll4"/>
                    <div class="goll5"/>
                </div>
                <h1 className='c-u-title'>Have any Questions?</h1>
                
            </div>
            <div className='c-u-form-section'>
                <div className="c-u-info">
                    <div className='c-u-information'>
                        
                        <h4 className='main'>Contact Us</h4>
                        <ul  className='c-u-ul'>
                            <a href="index.html"><li className='other'><i class="fa-sharp fa-solid fa-location-dot"></i>2A, 58, Victor Crescent, Narre Warren, VIC 3809</li></a>
                            <a href="index.html"><li className='other' ><i class="fa-solid fa-envelope"></i>melbournemathshub@gmail.com</li></a> 
                            <a href="index.html"><li className='other'><i class="fa-solid fa-phone"></i>+61425660556</li></a>
                            
                        </ul>
                    </div>
                    <div class="c-u-image">
                        <img src="assets/contact us img.png" alt="" srcset="" className="c-u-img"/>
                    </div>
                </div>
                
                <div className="c-u-form">
                    <form ref={form} onSubmit={sendEmail} noValidate>
                            <div className={`input-box ${!isFormValid ? 'invalid' : ''}`}>
                                <input type="text" name="user_name" placeholder="First name *" required />
                            </div>
                            <div className="input-box ">
                                <input type="text" name="last_name"  placeholder="Last name" />
                            </div>
                            <div className={`input-box ${!isFormValid ? 'invalid' : ''}`}>
                                <input type="text" name="phone_no"  placeholder="Phone number *" required />
                            </div>
                            <div className={`input-box ${!isFormValid ? 'invalid' : ''}`}>
                                <input type="email" name="user_email" placeholder="Email *" required/>
                            </div>
                            <div class="input-box message-box">
                                <textarea name="message" placeholder="Enter your message"/>
                            </div>
                            <button class="btn"  type="submit" value="Send" >Send message</button> 
                            {!isFormValid && <p className="error">Please fill in all required fields.</p>}
                    </form>            
                </div>
            </div>
        </div> 
  )
}

export default Contactus
        