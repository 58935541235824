import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <div className='hero-section'>
        <div className="col-1">
              <img src="assets/solar_eraser-bold-duotone.png" alt="" className='ereser'/>
              <h1 className="hero-heading">WELCOME TO MATHS <br /> HUB MELBOURNE </h1>
              <p className="hero-text">Creating a connect world for aspiring students where <br /> geographical restrictions do not limit their <br /> educational goals and get Maths help today</p>
              <img src="assets/pencil-light.png" alt="" className='pencil-light' />
        </div>
        <div className="col-2">
              <img src="assets/Vector.png" alt="" className='Vector' />
              <img src="assets/background1.png" alt="" className='controller' />
        </div>
    </div>
  );
}

export default Hero;
