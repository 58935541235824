import React from 'react'

import './AboutUs.css'

export default function AboutUs() {
  return (
    < >
        {/* about-section1-start */}
        
    <div className='center'>
      <div className='frist'>
          <div className='cricle'>
                <img src="assets/Ellipse 45.png" alt="" className='fc'/>
                <img src="assets/Ellipse 37.png" alt="" className='ft'/>
                <img src="assets/Ellipse 38.png" alt="" className='sc'/>
                <img src="assets/Ellipse 39.png" alt="" className='td'/>
                <img src="assets/Ellipse 43.png" alt="" className='sf'/>
                <img src="assets/Ellipse 44.png" alt="" className='ff'/>
                <img src="assets/Ellipse 35.png" alt="" className='ef'/> 
          </div>
          <h1 className='about us'>About Us</h1>
      </div>
      <div className='space2'></div>
      {/* about-section1-end */}
      <div className="second">
        <div className='cricle'>
            <img src="assets/Ellipse 46.png" alt="" className='nt'/>
            <img src="assets/Ellipse 42.png" alt="" className='tn'/>
            <img src="assets/Ellipse 49.png" alt="" className='ln'/>
            <img src="assets/Ellipse 42.png" alt="" className='fr'/>
        </div>
            <h1 className='meet'>Meet Your Tutor</h1>
            <img src="assets/myt-png.png" alt="" className='group88'/>
            <h2 className='dr-sahan'>Dr.Sahan</h2>
            <p className='descrption'>
              Sahan believes that every child is unique, and tailored teaching approaches for each student 
             can bring out the best in them. So Sahan adjusts his teaching methodologies to match each student’s 
              needs and learning style. He commits to building a strong foundation in mathematical concepts so the 
              student can succeed in maths. He used modern tools to facilitate teaching and courage learning through fun 
              activities such as games and puzzels for kids.
            </p>
      </div>
    </div>

    {/* Gallery */}

    <div className='gallery-bg'>
      <div className='circle-1'/>
      <div className='circle-2'/>
      <div className='circle-3'/>
      <h2>Gallery</h2>
    
      <div className='gallery'>
           
                  <div className='first-row'> 
                    <img src='assets/pics/full pic.jpg' className='dr-sahan-img-1' alt=""></img>
                    <div className='first-col'> 
                        <img src='assets/pics/pic 4.jpg' className='gallery-img-4' alt=""></img>
                        <img src='assets/pics/drsahandp.jpg' className='dr-sahan-img-2' alt=""></img>
                    </div>
                    <img src='assets/pics/pic 10.jpg' className='gallery-img-10' alt=""></img>
                  </div>
                  
                  <div className='first-row'>
                    <img src='assets/pics/facebook screenshot.jpg' className='dr-sahan-img-3' alt=""/>
                    <img src='assets/pics/pic 9.jpg' className='gallery-img-9' alt=""></img>
                    <div className='second-col'> 
                        <img src='assets/pics/pic 7.jpg' className='gallery-img-7' alt=""/>
                        <img src='assets/pics/pic 5.jpg' className='gallery-img-5' alt=""/>
                    </div>
                  </div>
      
      </div>
      
    </div>


    </ >
  )
}
