import React from "react";
import "./test.css";

function ServiceSectionTest() {
  return (
    <div className="k-bg">
      <div className="k-Benefits">Benefits</div>
      <div className="k-our">Our Services</div>
      <div className="k-row">
        <div className="k-col">
          <div class="k-wrapper">
            <div className="k-card-border">
              <div class="k-card-hover-01">
                <div class="k-card-hover__content">
                  <hr className="k-card-hr" />
                  <h3 class="k-card-hover__title">Online Tutoring</h3>
                  <a href="/" class="k-card-hover__link">
                    <span className="k-card-span">
                      Through interactive tools and virtual whiteboards, I'll 
                      explain concepts and problem-solving techniques in a clear
                      and concise manner. We'll tackle examples step-by-step,
                      solve challenging problems, and enhance your problem-solving skills.
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="k-card-border">
              <div class="k-card-hover-02">
                <div class="k-card-hover__content">
                  <hr className="k-card-hr" />
                  <h3 class="k-card-hover__title">One To One Tutoring</h3>
                  <a href="/" class="k-card-hover__link">
                    <span className="k-card-span">
                    One-to-one tutoring is a personalized approach to 
                    education where I provide individualized 
                    instruction to a single student. This type of tutoring 
                    is highly effective as it allows for focused attention and 
                    tailored instruction to address the specific needs of the student.
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="k-card-border">
              <div class="k-card-hover-03">
                <div class="k-card-hover__content">
                  <hr className="k-card-hr" />
                  <h3 class="k-card-hover__title">Classroom Tutoring</h3>
                  <a href="/" class="k-card-hover__link">
                    <span className="k-card-span">
                      In a classroom tutoring setting, I assist students in understanding
                      and reinforcing the material covered in their regular classes.
                      I may work with the entire class or with small groups of students
                      who require additional attention or have specific learning needs.
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="k-card-border">
              <div class="k-card-hover-04">
                <div class="k-card-hover__content">
                  <hr className="k-card-hr" />
                  <h3 class="k-card-hover__title">Home Tutoring</h3>
                  <a href="/" class="k-card-hover__link">
                    <span className="k-card-span">
                      In home tutoring, I travel to the student's residence
                      and conduct sessions in a one-on-one or small group setting.
                      This direct interaction enables me to focus on the student's
                      specific areas of difficulty, address their learning style,
                      and adapt my teaching methods.
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="k-section-02">
        <div className="k-sec-row">
          <div className="k-sec-col">
            <div className="k-row">
              <span> 500+</span>
            </div>
            <div className="k-row">STUDENTS ENROLLED</div>
          </div>
          <hr className="k-sec-hr" />
          <div className="k-sec-col">
            <div className="k-row">
              <span> 80+</span>
            </div>
            <div className="k-row">HOURS TUTORED</div>
          </div>
          <hr className="k-sec-hr" />
          <div className="k-sec-col">
            <div className="k-row">
              <span>17</span>
            </div>
            <div className="k-row">SUCCESS STORIES </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceSectionTest;
