import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

export default function Navbar() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const handleClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };


  useEffect(() => {
    const handleScroll = () => {
      if (isDropdownOpen) {
        setDropdownOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const navbarClassName = isSticky ? 'navbar sticky' : 'navbar';
  const placeholderHeight = isSticky ? (window.innerWidth > 495 ? '80px' : '0px') : '0px';

  return (
    <div className='nav-body'>
      
        
            <div className={`dropdown_menu ${isDropdownOpen ? 'open' : 'close'}`}>
              <ul>
                <li><NavLink to="/" exact activeClassName="active">Home</NavLink></li>
                <li><NavLink to="/about" activeClassName="active">About</NavLink></li>
                <li><NavLink to="/contact" activeClassName="active">Contact Us</NavLink></li>
              </ul>
            </div>
          
        
          <div className={navbarClassName}>
              <div className='icon'>
                 <img src='assets/nav-circle.png' alt='' className='nav-bg-img'/>
                  <i className={isDropdownOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-bars'} onClick={handleClick} />
              </div>
              <div className="logo">
                  <NavLink to="/"><img src="assets/Logo1.png" alt="" className='nav-logo'/></NavLink>
              </div>
              <div className='link-bar'>
                  <ul className='links'>
                      <li className='link'><NavLink to="/" exact activeClassName="active" className='nav-link'>Home</NavLink></li>
                      <li className='link'><NavLink to="/about" activeClassName="active" className='nav-link'>About</NavLink></li>
                      <li className='link'><NavLink to="/contact" activeClassName="active" className='nav-link'>Contact Us</NavLink></li>
                  </ul>
              </div>
              <NavLink to="https://lms.mathshubmelbourne.com.au"><img src="assets/Profileicon.png" alt="" className='profile'/></NavLink>
          </div>

          <div style={{ height: placeholderHeight }}></div>

    </div>
  );
}