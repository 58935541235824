import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import HomePage from './Pages/Home/Home_page';
import AboutPage from './Pages/About/About_Page';
import ContactPage from './Pages/Contact/Contact_Page';
import ScrollToTop from './Components/scroll/scroll';


function App() {

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;

